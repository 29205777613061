<template>
  <!-- <div > -->

  <div
    class="row position-relative"
    :class="
      RoutePage == 'QuizFinal'
        ? 'm-0 p-0 w-100 big-div-quiz'
        : ' w-85 big-div'
    "
    style="z-index: 100"
    :style="getPageDesign()"
  >
    <!-- BackGroundDiv start-->
    <div
      class="bg lazyload"
      :data-bg="MainImgSrc(getImageSrc(pageDesign.imageBlock, isDesktopView))"
      v-if="pageDesign.pageStyles.imageAlign == 'no'"
      :key="getImageSrc(pageDesign.imageBlock, isDesktopView)"
      :style="
        getbackgroundImage(
          IsImageAdded,
          pageDesign.imageBlock,
          pageDesign.pageStyles.imageAlign,
          isDesktopView
        ) + getBgDivStyles()
      "
    ></div>
    <!-- BackGroundDiv end-->
    <div
      class="px-0 px-0"
      v-show="showLeftImage"
      :class="[
        {
          'col-12 col-lg-12 col-xs-12': !isDesktopView,
          'image-division col-6': isDesktopView,
        },
      ]"
      :style="
        calculateImageWidth(
          pageDesign.imageBlock,
          isDesktopView,
          IsImageVariableWidthActive
        )
      "
    >
      <div :class="isDesktopView ? 'image-division' : ''">
        <div :style="height100(isDesktopView)">
          <LazyImage
            :key="getImageSrc(pageDesign.imageBlock, isDesktopView)"
            :style="
              getUniversalImageStyle(
                isDesktopView,
                pageDesign.imageBlock.style,
                IsImageVariableWidthActive,
                pageDesign.imageBlock
              ) +
              getImageRotation(pageDesign.imageBlock.style) +
              getImageRotation(pageDesign.imageBlock.style)
            "
            :class="[
              `pic-${getSelectedPage.order}`,
              getImageSrc(pageDesign.imageBlock, isDesktopView) ==
              '/images/default_start_page.png'
                ? 'default-bg'
                : '',
            ]"
            :src="MainImgSrc(getImageSrc(pageDesign.imageBlock, isDesktopView))"
            alt
          />
        </div>
      </div>
    </div>

    <div
      :class="[!IsImageAdded ? 'col-12' : imageAddedLayout]"
      class=" "
      :style="
        getMainPaddingCss(pageDesign.pageStyles, isDesktopView) +
        getScrollCSS(
          pageDesign.pageStyles,
          isDesktopView,
          pageDesign.imageBlock.style
        ) +
        calculateContentWidth(
          pageDesign.imageBlock,
          isDesktopView,
          IsImageVariableWidthActive,
          pageDesign.pageStyles
        )
      "
    >
      <div
        style="
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
          height: 100%;
        "
      >
        <div style="overflow-y: scroll" class="h-100">
          <div
            v-for="(pro, ind) in pageDesign.blocksArray"
            :key="'preview' + ind"
            :class="`item-${ind}`"
            :data-aos="animationType"
              :data-aos-duration="getAnimationSpeedinMillis"
              :data-aos-delay="AOSAnimationDelay(ind)"
              :data-aos-anchor="`.item-${ind == 0 ? ind : ind - 1}`"
          >
            <div
              id="preview"
              :style="getHeightWidth(pro.style)"
              class="content"
              style="position: relative"
            >
              <div
                v-if="pro.type == 'text'"
                :style="
                  getColorCss(pro.style) +
                  getPaddingCss(pro.style) +
                  getMarginCss(pro.style, isDesktopView) +
                  getColorWithOpacity(pro.style) +
                  getTextRotate(pro.style) +
                  getLineHeight(pro.style) +
                  getTextAlign(pro.style) +
                  getFontStyle(pro.style,isDesktopView)
                "
                v-html="pro.content"
              ></div>

              <div
                v-if="pro.type == 'tooltip'"
                :style="`display:flex;justify-content: ${pro.style.position} ;  `"
              >
                <button
                  :style="
                    getButtonCss(pro.style, isDesktopView) +
                    getCircularBtn(pro.isBtnRounded)
                  "
                  @mouseenter="pro.isTooltipVisible = true"
                  @mouseleave="pro.isTooltipVisible = false"
                >
                  <span v-if="pro.useIcon">
                    <BIcon :icon="pro.selectedIcon" />
                  </span>

                  <span
                    v-else
                    :style="{
                      opacity: `${pro.style.textOpacity}%`,
                      fontSize: `${pro.style.fontSize}px`,
                    }"
                  >
                    {{ pro.text }}
                  </span>
                </button>
                <ToolTipComp
                  v-if="pro.isTooltipVisible"
                  :tooltipData="pro"
                  :isDesktopView="isDesktopView"
                />
              </div>
              <div
                v-if="pro.type == 'html'"
                :style="
                  getMarginCss(pro.style, isDesktopView) +
                  getColorWithOpacity(pro.style) +
                  getTextRotate(pro.style) +
                  getTextAlign(pro.style)
                "
              >
                <HtmlElement :pro="pro" :isDesktopView="isDesktopView" />
              </div>

              <div
                v-if="pro.type == 'sliderRangeInput'"
                :style="getMarginCss(pro.style, isDesktopView)"
              >
                <SliderInputComp
                  :pro="pro"
                  :isDesktopView="isDesktopView"
                  :isEditor="true"
                  @updateSliderValue="updateSliderValue($event)"
                />
              </div>
            </div>
          </div>
        </div>

        <!-- Stripe Back Button -->
        <div>
          <div
            :style="getHeightWidth(StaticButtonElement.style)"
            class="content"
          >
            <div class="w-100">
              <div
                class="d-flex"
                :style="
                  getBackNextButtonPosition(
                    StaticButtonElement,
                    CurrentPage.value
                  )
                "
              >
                <button
                class="ButtonHover"
                  style="z-index: 1000"
                  :style="
                    getCommonButtonCSS(
                      StaticButtonElement.style,
                      isDesktopView
                    ) +
                    getBackNextButtonCss(
                      StaticButtonElement.content.backButton.style
                    )
                  "
                  @click="backPage"
                  v-if="!StaticButtonElement.content.backButton.hideBackButton"
                >
                  <span
                    :style="{
                      opacity: `${StaticButtonElement.content.backButton.style.textOpacity}%`,
                      fontSize: `${StaticButtonElement.content.backButton.style.fontSize}px`,
                    }"
                    >{{ StaticButtonElement.content.backButton.text }}</span
                  >
                </button>
                <button
                  style="z-index: 1000"
                  :style="
                    getCommonButtonCSS(
                      StaticButtonElement.style,
                      isDesktopView
                    ) +
                    getBackNextButtonCss(
                      StaticButtonElement.content.nextButton.style
                    )
                  "
                   class="ButtonHover"
                  @click="initSliderValue()"
                >
                  <span
                    :style="{
                      opacity: `${StaticButtonElement.content.nextButton.style.textOpacity}%`,
                      fontSize: `${StaticButtonElement.content.nextButton.style.fontSize}px`,
                    }"
                    >{{ StaticButtonElement.content.nextButton.text }}</span
                  >
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      class="px-0 px-0"
      v-show="showRightImage"
      :class="[
        {
          'col-12 col-lg-12 col-xs-12': !isDesktopView,
          'image-division col-6': isDesktopView,
        },
      ]"
      :style="
        calculateImageWidth(
          pageDesign.imageBlock,
          isDesktopView,
          IsImageVariableWidthActive
        )
      "
    >
      <div :class="isDesktopView ? 'image-division' : ''">
        <div :style="height100(isDesktopView)">
          <LazyImage
            :key="getImageSrc(pageDesign.imageBlock, isDesktopView)"
            :style="
              getUniversalImageStyle(
                isDesktopView,
                pageDesign.imageBlock.style,
                IsImageVariableWidthActive,
                pageDesign.imageBlock
              ) +
              getImageOpacity(pageDesign.imageBlock.style) +
              getImageRotation(pageDesign.imageBlock.style)
            "
            :class="[
              `pic-${getSelectedPage.order}`,
              getImageSrc(pageDesign.imageBlock, isDesktopView) ==
              '/images/default_start_page.png'
                ? 'default-bg'
                : '',
            ]"
            :src="MainImgSrc(getImageSrc(pageDesign.imageBlock, isDesktopView))"
            alt
          />
        </div>
      </div>
    </div>
  </div>

  <!-- </div> -->
</template>

<script>
//   import axios from "axios";
import { mapGetters, mapActions, mapMutations } from "vuex";
import cssFunctionMixin from "../customize/mixin/cssFunction.js";
export default {
  mixins: [cssFunctionMixin],
  props: [
    "isDesktopView",
    "apiToken",
    "isTest",
    "animationType",
    "RoutePage",
    "getIsAnimationEnabled",
    "getAnimationSpeedinMillis",
  ],
  components: {
    HtmlElement: () =>
      import(/* webpackChunkName: "HtmlElement" */ "./HtmlElement.vue"),
    ToolTipComp: () =>
      import(/* webpackChunkName: "ToolTipComp" */ "./ToolTipComp.vue"),
    SliderInputComp: () =>
      import(
        /* webpackChunkName: "SliderInputComp" */ "./components/SliderInputComp.vue"
      ),
  },
  data() {
    return {
      activeSideBar: "pageStyle",
      pageDesign: {},
      email: "",
      name: "",
      phone: "",
      date: "",
      website: "",
      organisation: "",
      address_1: "",
      address_2: "",
      city: "",
      zip_code: "",
      state: "",
      country: "",
      delayIncrement: 0.2, // The delay between each item
      isPaypalBtnLoading: false,
      paypal_sdk_url: `https://www.paypal.com/sdk/js`,
      paypalIntent: `capture`,
      sliderInputValue: null,
    };
  },
  computed: {
    StaticButtonElement() {
      const index = this.pageDesign.blocksArray.findIndex(
        (x) => x.type == "button"
      );
      return this.pageDesign.blocksArray[index];
    },
    CurrentPage() {
      return this.getSelectedPage;
    },

    sliderPurpose() {
      const index = this.pageDesign.blocksArray.findIndex(
        (x) => x.type == "sliderRangeInput"
      );
      return index > -1
        ? this.pageDesign.blocksArray[index].selectedPurpose == "other"
          ? this.pageDesign.blocksArray[index].otherPurposeKey
          : this.pageDesign.blocksArray[index].selectedPurpose
        : null;
    },

    showLeftImage() {
      return (
        (this.isDesktopView &&
          this.pageDesign.imageBlock.style.displayDesktop &&
          this.pageDesign.pageStyles.imageAlign == "left") ||
        (!this.isDesktopView &&
          this.pageDesign.imageBlock.style.displayMobile &&
          (this.pageDesign.pageStyles.imageAlign == "left" ||
            this.pageDesign.pageStyles.imageAlign == "right"))
      );
    },
    showRightImage() {
      return (
        this.isDesktopView &&
        this.pageDesign.imageBlock.style.displayDesktop &&
        this.pageDesign.pageStyles.imageAlign == "right"
      );
    },
    firstTextIndex() {
      return this.pageDesign.blocksArray.findIndex((e) => e.type == "text");
    },
    firstButtonIndex() {
      return this.pageDesign.blocksArray.findIndex((e) => e.type == "button");
    },
    getPriceIndex() {
      return this.pageDesign.blocksArray.findIndex((e) => e.type == "price");
    },
    getPricePaymentButtonIndex() {
      return this.pageDesign.blocksArray.findIndex(
        (e) => e.type == "paymentButton"
      );
    },
    getPaymentLinkValue() {
      let index = this.getPriceIndex;
      if (index > -1) {
        return this.pageDesign.blocksArray[index].stripePaymentLink;
      } else {
        return null;
      }
    },
    ...mapGetters([
      "getSelectedPage",
      "getUploadedImage",
      "getTotalPages",
      "getPreviewSettings",
      "getQuizStarted",
      "getAnimationSetting",
      "getSavedLeadID",
    ]),
    CurrentSelectedPage() {
      return this.getSelectedPage;
    },
    IsImageAdded() {
      if (
        this.getImageSrc(this.pageDesign.imageBlock, this.isDesktopView) !== ""
      )
        return true;
      return false;
    },
    IsImageVariableWidthActive() {
      return this.IsImageAdded && this.pageDesign.pageStyles.imageAlign != "no"
        ? true
        : false;
    },
    imageAddedLayout() {
      if (this.pageDesign.pageStyles.imageAlign == "no") return "col-12";
      if (!this.isDesktopView) return "col-12";
      // return "col-12 col-xs-12 col-md-6 col-sm-12 px-4 col-12 col-xs-12";
      return "col-12 col-xs-12 col-sm-6 col-md-6 ";
    },
    getStripePriceObjIndex() {
      return this.pageDesign.blocksArray.findIndex((e) => e.type == "price");
    },
    currentPaymentMethod() {
      return this.pageDesign.blocksArray[this.getPriceIndex]
        .selectedPaymentMethod;
    },
    paymentBlockData() {
      return this.pageDesign.blocksArray[this.getPriceIndex];
    },
    getPaypalClientID() {
      return this.pageDesign.blocksArray[this.getPriceIndex].paypalClientID;
    },
    PaypalButtonStyles() {
      return this.pageDesign.blocksArray[this.getPricePaymentButtonIndex]
        .paypalButtonData;
    },
    isRecurringPayment() {
      return this.pageDesign.blocksArray[this.getPriceIndex].isRecuringPayment;
    },
  },

  created() {
    this.pageDesign = this.getStartedPageValues();
  },
  methods: {
    AOSAnimationDelay(index) {
      const baseDelay = parseInt(this.getAnimationSpeedinMillis) || 200;
      return this.getIsAnimationEnabled ? (index + 1) * baseDelay : 50;
    },
 async   initSliderValue() {
      if (this.sliderInputValue) {
        const { exactValue, type, rangeAValue, rangeBValue } =
          this.sliderInputValue;

          let payloadObj= {}
        switch (this.sliderInputValue.type) {
          case "exact": {
            payloadObj = {
              id: this.CurrentSelectedPage.id,
              type: type,
              purpose: this.sliderPurpose,
              value: exactValue,
            };

           

            break;
          }

          case "range":{
            payloadObj= {
              id: this.CurrentSelectedPage.id,
              type: type,
              purpose: this.sliderPurpose,
              value: [rangeAValue, rangeBValue],
            };

            

            break;
          }
          default:
            break;
        }

        await this.updateSelectedRangeSlider(payloadObj);
        // await this.IncrementQuestionAnalyticsCount(obj, "normal" );
        this.goToNextPreviewPage(this.getSelectedPage.value);
      }
    },
    updateSliderValue(evt) {
      this.sliderInputValue = evt;
    },
    UpdateDynamicText() {
      if (this.getSavedLeadID.full_name !== "") {
        this.name = this.getSavedLeadID.full_name;
      }
      if (this.getSavedLeadID.email !== "") {
        this.email = this.getSavedLeadID.email;
      }
      if (this.getSavedLeadID.phone_number !== "") {
        this.phone = this.getSavedLeadID.phone_number;
      }
      if (this.getSavedLeadID.date !== "") {
        this.date = this.getSavedLeadID.date;
      }
      if (this.getSavedLeadID.website !== "") {
        this.website = this.getSavedLeadID.website;
      }
      if (this.getSavedLeadID.organisation !== "") {
        this.organisation = this.getSavedLeadID.organisation;
      }
      if (
        Object.prototype.hasOwnProperty.call(this.getSavedLeadID, "address1") &&
        this.getSavedLeadID.address1 !== ""
      ) {
        this.address_1 = this.getSavedLeadID.address1;
      }
      if (
        Object.prototype.hasOwnProperty.call(this.getSavedLeadID, "address2") &&
        this.getSavedLeadID.address2 !== ""
      ) {
        this.address_2 = this.getSavedLeadID.address2;
      }
      if (
        Object.prototype.hasOwnProperty.call(this.getSavedLeadID, "city") &&
        this.getSavedLeadID.city !== ""
      ) {
        this.city = this.getSavedLeadID.city;
      }
      if (
        Object.prototype.hasOwnProperty.call(this.getSavedLeadID, "country") &&
        this.getSavedLeadID.country !== ""
      ) {
        this.country = this.getSavedLeadID.country;
      }
      if (
        Object.prototype.hasOwnProperty.call(this.getSavedLeadID, "state") &&
        this.getSavedLeadID.state !== ""
      ) {
        this.state = this.getSavedLeadID.state;
      }
      if (
        Object.prototype.hasOwnProperty.call(this.getSavedLeadID, "zip_code") &&
        this.getSavedLeadID.zip_code !== ""
      ) {
        this.zip_code = this.getSavedLeadID.zip_code;
      }
    },
    backPage() {
      this.backPageAnimation();
      this.goToBackPreviewPage();
    },
    ...mapMutations([
      "setQuizStarted",
      "nextPageAnimation",
      "backPageAnimation",
    ]),
    ...mapActions(["goToNextPreviewPage", "goToBackPreviewPage","updateSelectedRangeSlider"]),

    getPageDesign() {
      let design =
        "text-align:" +
        this.pageDesign.pageStyles.textAlign +
        ";color:" +
        this.pageDesign.pageStyles.color +
        "; background:" +
        this.pageDesign.pageStyles.backgroundColor +
        "; width:" +
        this.pageDesign.pageStyles.width +
        "px; " + this.getQuizPageHeight(this.pageDesign.pageStyles.height,this.RoutePage,this.isTest);

      return design;
    },
    getStartedPageValues() {
      let getStartedDesignValues = {};
      if (this.getTotalPages && this.getSelectedPage) {
        getStartedDesignValues = this.getSelectedPage.pageDesign;
      }
      return getStartedDesignValues;
    },

    covertQuestionTitle() {
      this.pageDesign.blocksArray.forEach((x) => {
        if (x.type == "text") {
          x.questionTitle = true;
        }
      });
    },
    // Paypal Functionality
  },
  mounted() {
    this.UpdateDynamicText();
  },
};
</script>

<style>
.arrow1::before {
  content: "\2023";
  font-size: 58px;
  position: absolute;
  top: -47px;
  right: 0px;
  transform: rotate(26deg);
  color: #ffffff;
}

.slideRTL {
  animation-name: slide-in;
  animation-duration: var(--animation-speed);
  animation-timing-function: forwards;
  animation-fill-mode: both;
}

.slideLTR {
  animation-name: slide-out;
  animation-duration: var(--animation-speed);
  animation-timing-function: forwards;
  animation-fill-mode: both;
}
</style>
